import React from 'react';

// TODO include link back to home
const NotFound = () => {
  return (
    <div>
      Page not found
    </div>
  );
};

export default NotFound;
